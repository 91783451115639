var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register page"},[_c('div',{staticClass:"content"},[(!_vm.customer.user_subscription_id)?_c('div',{staticClass:"rounded mt-6 bg-yellow-lighter border border-grey-light px-4 py-3"},[_c('p',{staticClass:"text-center text-sm flex items-center justify-center text-grey-darkest"},[_c('span',{staticClass:"leading-none inline-block mr-2"},[_c('svg',{attrs:{"stroke":"currentColor","fill":"currentColor","stroke-width":"0","viewBox":"0 0 24 24","size":"20","height":"20","width":"20"}},[_c('path',{attrs:{"d":"M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"}})])]),_vm._v("Restricted Access. Purchase a plan to complete your registration "),_c('a',{staticClass:"inline-block ml-1 underline text-primary-dark active",attrs:{"href":"/change-plan"}},[_vm._v("View plans")])])]):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"bordered_b"},[_c('h2',{staticClass:"big-title font-bold text-left py-10 text-2xl"},[(_vm.customer.user_subscription_id)?_c('span',[_vm._v("Change plan")]):_c('span',[_vm._v("Select plan")])])]),_c('div',{staticClass:"manager-plans mb-10 mt-10 "},[_c('div',{staticClass:"tabs flex items-center justify-center"},[_c('div',{staticClass:"tabs-wrap flex "},[_c('button',{staticClass:"font-bold text-primary",class:{'active underline bold-green': !_vm.is_route_manager,
            ' text-grey-darker': _vm.is_route_manager},attrs:{"type":"button"},on:{"click":function($event){_vm.is_route_manager = false}}},[_vm._v("Fleet manager")])])]),_vm._m(0),_c('div',{staticClass:"subscription_plans grid grid-cols-4 gap-15 m-auto"},_vm._l((_vm.is_route_manager ? _vm.annual_subscription_plans_route : _vm.annual_subscription_plans_fleet),function(plan,index){return _c('div',{key:index,staticClass:"plan flex flex-col items-center justify-between px-8 py-6 height_av"},[_c('div',{staticClass:"flex flex-col justify-between"},[_c('strong',{staticClass:"block font-semibold text-grey-darkest text-base capitalize mb-2"},[_vm._v(_vm._s(plan.name))]),_c('h5',{staticClass:"text-3xl flex items-center justify-center leading-tight mb-3"},[_c('span',{staticClass:"text-grey-darker font-normal text-base"},[_vm._v("£")]),_c('strong',{staticClass:"inline-block text-grey-darkest font-medium mx-1"},[_vm._v(_vm._s(plan.price/100))]),_c('span',{staticClass:"text-grey-darker font-normal text-base"},[_vm._v(" / "+_vm._s(plan.billing_period))])])]),_c('div',{staticClass:"flex flex-col justify-between"},[_c('p',{staticClass:"text-grey-darker text-center mb-6"},[_vm._v(_vm._s(plan.description))]),_c('button',{staticClass:"green-white w-full font-medium ",class:{'pointer': _vm.customer.user_subscription_id && _vm.user_subscription.plan_id != plan.external_plan_id},attrs:{"type":"button","disabled":_vm.customer.user_subscription_id &&
                  _vm.user_subscription.plan_id == plan.external_plan_id &&
                  !_vm.check30DayOfEnds(_vm.user_subscription.next_billing_at)},on:{"click":function($event){_vm.chargeAction(plan, (_vm.user_subscription.plan_id == plan.external_plan_id && _vm.check30DayOfEnds(_vm.user_subscription.next_billing_at)))}}},[(_vm.customer.user_subscription_id && _vm.user_subscription.plan_id == plan.external_plan_id &&
                !_vm.check30DayOfEnds(_vm.user_subscription.next_billing_at))?_c('span',[_vm._v(" Upgrade plan ")]):(
                _vm.customer.user_subscription_id &&
                _vm.user_subscription.plan_id == plan.external_plan_id &&
                (_vm.check30DayOfEnds(_vm.user_subscription.next_billing_at) ||
                _vm.customer.user_subscription.status !== 'active')
              )?_c('span',[_vm._v(" Renew plan ")]):(_vm.customer.user_subscription_id && _vm.user_subscription.plan_id == plan.external_plan_id)?_c('span',[_vm._v(" Current plan ")]):_c('span',[_vm._v("Change plan")])])])])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center mt-5"},[_c('div',{staticClass:"manger-info text-center mt-1 w-1/2"},[_c('p',{staticClass:"font-semibold whitespace-pre-line"},[_vm._v("If you have 1 or more vehicles, our Fleet plans are what you need.")]),_c('p',{staticClass:"font-semibold whitespace-pre-line"},[_vm._v("Create compliant routes, register your vehicles and create driver logins")])])])}]

export { render, staticRenderFns }