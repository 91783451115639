<template>
  <div class="register page">
    <div class="content">
      <div class="rounded mt-6 bg-yellow-lighter border border-grey-light px-4 py-3" v-if="!customer.user_subscription_id">
        <p class="text-center text-sm flex items-center justify-center text-grey-darkest">
          <span class="leading-none inline-block mr-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>
          </span>Restricted Access. Purchase a plan to complete your registration
          <a href="/change-plan" class="inline-block ml-1 underline text-primary-dark active">View plans</a>
        </p>
      </div>
    </div>
    <div class="content">
      <div class="bordered_b">
        <h2 class="big-title font-bold text-left py-10 text-2xl">
          <span v-if="customer.user_subscription_id">Change plan</span>
          <span v-else>Select plan</span>
        </h2>
      </div>

      <div class="manager-plans mb-10 mt-10 ">
        <div class="tabs flex items-center justify-center">
          <div class="tabs-wrap flex ">
            <!-- <button type="button" @click="is_route_manager = true" class="font-bold"
                    :class="{'active underline bold-green': is_route_manager,
              ' text-grey-darker': !is_route_manager}"
            >Route manager</button>
            <span class="ml-2 mr-2">|</span> -->
            <button type="button" @click="is_route_manager = false" class="font-bold text-primary"
                    :class="{'active underline bold-green': !is_route_manager,
              ' text-grey-darker': is_route_manager}"
            >Fleet manager</button>
          </div>

        </div>
        <div class="flex justify-center mt-5"><div class="manger-info text-center mt-1 w-1/2"><p class="font-semibold whitespace-pre-line">If you have 1 or more vehicles, our Fleet plans are what you need.</p>
          <p class="font-semibold whitespace-pre-line">Create compliant routes, register your vehicles and create driver logins</p>
        </div>
        </div>
        <div class="subscription_plans grid grid-cols-4 gap-15 m-auto">
          <div class="plan flex flex-col items-center justify-between px-8 py-6 height_av"
               v-for="(plan, index) in is_route_manager ? annual_subscription_plans_route : annual_subscription_plans_fleet" :key="index">
            <div class="flex flex-col justify-between">
              <strong class="block font-semibold text-grey-darkest text-base capitalize mb-2">{{ plan.name }}</strong>
              <h5 class="text-3xl flex items-center justify-center leading-tight mb-3">
                <span class="text-grey-darker font-normal text-base">£</span>
                <strong class="inline-block text-grey-darkest font-medium mx-1">{{ plan.price/100 }}</strong>
                <span class="text-grey-darker font-normal text-base"> / {{ plan.billing_period }}</span>
              </h5>
            </div>
            <div class="flex flex-col justify-between">
              <p class="text-grey-darker text-center mb-6">{{plan.description}}</p>
              <button
                  type="button"
                  class="green-white w-full font-medium "
                  :class="{'pointer': customer.user_subscription_id && user_subscription.plan_id != plan.external_plan_id}"
                  :disabled="
                    customer.user_subscription_id &&
                    user_subscription.plan_id == plan.external_plan_id &&
                    !check30DayOfEnds(user_subscription.next_billing_at)
                  "
                  @click="chargeAction(plan, (user_subscription.plan_id == plan.external_plan_id && check30DayOfEnds(user_subscription.next_billing_at)))"
              >
                <span v-if="customer.user_subscription_id && user_subscription.plan_id == plan.external_plan_id &&
                  !check30DayOfEnds(user_subscription.next_billing_at)">
                  Upgrade plan
                </span>
                <span v-else-if="
                  customer.user_subscription_id &&
                  user_subscription.plan_id == plan.external_plan_id &&
                  (check30DayOfEnds(user_subscription.next_billing_at) ||
                  customer.user_subscription.status !== 'active')
                ">
                  Renew plan
                </span>
                <span v-else-if="customer.user_subscription_id && user_subscription.plan_id == plan.external_plan_id">
                  Current plan
                </span>
                <span v-else>Change plan</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "../../services/messages";
import moment from "moment";

export default {
  name: "ChangePlan",
  data() {
    return {
      logo_url: null,
      annual_subscription_plans_fleet: null,
      annual_subscription_plans_route: null,
      is_route_manager: false,
      customer: JSON.parse(localStorage.getItem('user')) || null,
      user_subscription: []
    }
  },
  methods: {
    ...mapMutations([
      'setLoading'
    ]),
    async chargeAction(plan, isRenew){

      const { callbacks = {} } = {};
      const user = JSON.parse(localStorage.getItem('user'));

      this.getCbInstance.setCheckoutCallbacks(cart => {
        console.log(cart)
        return {
          loaded() {
            console.log('checkout opened');
            callbacks?.loaded?.();
          },
          close() {
            console.log('checkout closed');
            callbacks?.close?.();
          },
          success(hostedPageId) {
            console.log('success');
            callbacks?.success?.(hostedPageId);
          },
          step(value) {
            callbacks?.step?.(value);
          },
        };
        /* eslint-enable no-unused-expressions */
      });

      const http = this.$http;
      let endpoint = 'new-subscription';

      if (this.customer.user_subscription_id) {
        endpoint = 'update-subscription';
      }

      if (isRenew) {
        endpoint = 'renew-subscription';
      }

      if (this.user_subscription.status === 'cancelled') {
        endpoint = 'reactivate-subscription';
      }

      this.getCbInstance.openCheckout({
        hostedPage: async () =>
            await http.post(`${http.apiUrl()}/subscriptions/${endpoint}`, {plan, user})
                .then(resp => resp?.data?.hosted_page),
        error(errors) {
          if (errors.response) {
            const msg = errors?.response?.data?.message?.error_msg || errors?.response?.data?.message || 'Oops. Something went wrong...';
            return infoMessage(msg.replaceAll('_', ' '), 'error');
          }
        },
        close() {

        },
        success() {

        }
      });
    },
    logoUrl() {
      this.logo_url = `${this.$http.baseUrl()}/` + JSON.parse(localStorage.getItem('supportData')).icon_url || null
    },
    async getSubscriptionPlans() {
      try {
        const response = await this.$http.get(`${this.$http.apiUrl()}/subscription-plans`) || null;
        this.annual_subscription_plans_fleet = response?.data?.subscriptionPlansFleet;
        this.annual_subscription_plans_route = response?.data?.subscriptionPlansRoute;
      } catch (e) {
        console.log(e)
      }
    },
    async getUserSubscription() {
      if (!this.customer.user_subscription_id) {
        return
      }
      try {
        this.setLoading(true)
        const response = await this.$http.getAuth(`${this.$http.apiUrl()}/subscriptions/exists`);
        this.user_subscription = response.data.result.subscription;
        this.setLoading(false)
      } catch (e) {
        console.log(e)
        return this.setLoading(false)
      }
    },
    check30DayOfEnds(subscriptionEnd){
      let endTime = this.user_subscription.current_term_end;
      if (subscriptionEnd) {
        endTime = subscriptionEnd
      }

      let result = false;
      if (moment.unix(endTime).subtract(30, 'days') < moment()) {
        result = true;
      }
      return result
    },
  },
  async mounted() {
    await this.logoUrl();
    await this.getSubscriptionPlans();
    await this.getUserSubscription();
  },
  created () {
    window.addEventListener('message', receiveMessage, false);
    async function receiveMessage(event) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (event?.data?.key == 'cb.page_visited' && event?.data?.value == 'thankyou_screen') {
        let user = JSON.parse(localStorage.getItem('user'));
        user.user_subscription_id = user.id
        localStorage.setItem('user', JSON.stringify(user));
      }
      if(event?.data == 'cb.close' && user.user_subscription_id) {
        window.location.reload()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCbInstance'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.tabs-wrap {
  font-size: 14px;
  .manager-plans {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.subscription_plans {
  margin-top: 30px;
  .plan {
    background-color: rgb(243, 248, 254);
    border-radius: 4px;
  }
}
.tabs {
  position: relative;
}
</style>
